<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar> Administrare utilizator si monitorizare activitate </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <card-component title="Profil" icon="account" class="tile is-child">
          <div class="columns">
            <div class="column">
              <b-field label="Identificator utilizator">
                <span class="is-static">{{ userDetails.userId }}</span>
              </b-field>
              <b-field label="Nume">
                <span class="is-static">{{ userDetails.nickname }}</span>
              </b-field>
              <b-field label="E-mail">
                <span class="is-static">{{ userDetails.email }}</span>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Data înregistrării">
                <span class="is-static">{{
                  userDetails.createdAt | formatDateTime
                }}</span>
              </b-field>
              <b-field label="Ultima actualizare">
                <span class="is-static">{{
                  userDetails.updatedAt | formatDateTime
                }}</span>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Ultimul IP de conectare">
                <span class="is-static">{{ userDetails.lastIp }}</span>
              </b-field>
              <b-field label="Ultima dată de conectare">
                <span class="is-static">{{
                  userDetails.lastLogin | formatDateTime
                }}</span>
              </b-field>
              <b-field label="Număr de autentificări">
                <span class="is-static">{{ userDetails.loginsCount }}</span>
              </b-field>
            </div>
          </div>
        </card-component>
      </tiles>
      <tiles>
        <card-component
          title="Activitate de conectare (ultimele 5 zile)"
          icon="account"
          class="tile is-child"
        >
          <b-table
            :data="userActivity"
            ref="table"
            :opened-detailed="defaultOpenedDetails"
            detailed
            detail-key="logId"
            @details-open="(row) => $buefy.toast.open(`Expanded ${row.logId}`)"
          >
            <b-table-column
              field="date"
              label="Data activității"
              sortable
              centered
              v-slot="props"
            >
              {{ props.row.date | formatDateTime }}
            </b-table-column>

            <b-table-column
              field="connectionId"
              label="Identificator conexiune"
              v-slot="props"
            >
              {{ props.row.connectionId }}
            </b-table-column>

            <b-table-column field="ip" label="IP" sortable v-slot="props">
              {{ props.row.ip }}
            </b-table-column>
            <b-table-column
              field="activityType"
              label="Tip activitate"
              sortable
              v-slot="props"
            >
              {{ props.row.typeDescription }}
            </b-table-column>

            <b-table-column
              field="userAgent"
              label="Agent utilizator"
              sortable
              v-slot="props"
            >
              {{ props.row.userAgent }}
            </b-table-column>

            <template #detail="props">
              <article class="media">
                <div class="media-content">
                  <div class="content">
                    <div class="columns">
                      <div class="column">
                        <b-field label="Identificator conexiune">
                          <span class="is-static">{{
                            props.row.connectionId
                          }}</span>
                        </b-field>
                        <b-field label="Identificator activitate">
                          <span class="is-static">{{ props.row.logId }}</span>
                        </b-field>
                        <b-field label="Aplicatie client">
                          <span class="is-static">{{
                            props.row.clientName
                          }}</span>
                        </b-field>
                      </div>
                      <div class="column">
                        <b-field label="Tip conexiune">
                          <span class="is-static">{{
                            props.row.connection
                          }}</span>
                        </b-field>

                        <b-field label="Mobil">
                          <span class="is-static">{{
                            props.row.isMobile ? "Da" : "Nu"
                          }}</span>
                        </b-field>
                      </div>
                      <div class="column">
                        <b-field label="Oras">
                          <span class="is-static">{{
                            props.row.locationInfo.cityName
                          }}</span>
                        </b-field>
                        <b-field label="Tara">
                          <span class="is-static">{{
                            props.row.locationInfo.countryName
                          }}</span>
                        </b-field>
                        <b-field label="Fus orar">
                          <span class="is-static">{{
                            props.row.locationInfo.timeZone
                          }}</span>
                        </b-field>
                      </div>
                      <div class="column">
                        <b-field label="Latitudine">
                          <span class="is-static">{{
                            props.row.locationInfo.latitude
                          }}</span>
                        </b-field>
                        <b-field label="Longitudine">
                          <span class="is-static">{{
                            props.row.locationInfo.longitude
                          }}</span>
                        </b-field>
                        <b-field label="Continent">
                          <span class="is-static">{{
                            props.row.locationInfo.continentCode
                          }}</span>
                        </b-field>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </template>
          </b-table>
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>
import { pathOr } from "ramda";
import { mapState } from "vuex";
import CardComponent from "@/components/CardComponent";
import TitleBar from "@/components/TitleBar";
import HeroBar from "@/components/HeroBar";
import Tiles from "@/components/Tiles";

import UsersService from "@/services/users.service";

export default {
  name: "Profil",
  components: {
    Tiles,
    HeroBar,
    TitleBar,
    CardComponent,
  },
  data() {
    return {
      isLoading: false,
      defaultOpenedDetails: [1],
      userDetails: {},
      userActivity: [],
    };
  },
  async created() {
    try {
      this.isLoading = true;
      const id = pathOr(null, ["params", "id"], this?.$route);
      const [usersDetailsResponse, userActivitityResponse] = await Promise.all([
        this.getUserDetails(id),
        this.getUserActivity(id),
      ]);
      this.userDetails = usersDetailsResponse;
      this.userActivity = userActivitityResponse;
    } catch (error) {
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async getUserDetails(userId) {
      const { getUserDetails } = UsersService;
      const response = await getUserDetails(userId);
      const { data } = response;
      return data;
    },
    async getUserActivity(userId) {
      const { getUserLogs } = UsersService;
      const response = await getUserLogs(userId);
      const { data } = response;
      return data;
    },
  },
  computed: {
    titleStack() {
      return ["Utilizatori", "Profile"];
    },
  },
};
</script>
